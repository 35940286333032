









































































@import '../../utilities/config';

.a-cash {
  input {
    &::placeholder {
      color: gray('gray-150');
      font-weight: 300;
    }
  }
}
